import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Official Dentist of Tennis Canada and Rogers Cup | The Smile Stylist" 
    description="The Smile Stylist is the Proud Official Dentist of Tennis Canada and The Rogers Cup and looks forward to continue to help amateur Canadian athletes thrive." 
    keywords="best smiles, dentist blog, teeth canada, dental clinics, oakville, toronto, mississauga, canada" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="news-press hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="pr__cont">
              <h1>Toronto’s Smile Stylist Partners with Tennis Canada and Rogers Cup Presented by National Bank</h1>
              <h2>He’s Giving Canadian Tennis Fans Something to Smile About</h2>
            </div>

          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">

            <p className="lead">The family and cosmetic dentist Dr. Hisham Kaloti who is better known as Toronto's very own Smile Stylist, a "straight set" goes beyond another name for a perfect smile. And for the true tennis enthusiasts reading this, we need not say more.</p>

            <p>This summer, Dr. Kaloti is partnering up with Tennis Canada and Rogers Cup presented by National Bank in Toronto to offer an exclusive incentive to Canadian tennis fans who wish to attend the event.</p>
            <p>“A proud Canadian tennis fan myself, I’m ecstatic to be able to partner with Tennis Canada and Rogers Cup. These events are incredible to watch live,” Dr. Kaloti shares. “We hope this offer encourages even more people to attend this year. You’ve got to experience the passion of these players for yourself... it’s truly inspiring.”</p>
            <h2>About Rogers Cup presented by National Bank</h2>
            <p>Rogers Cup presented by National Bank is a Tennis Canada owned and operated world-class tournament, celebrating its 125th anniversary of the women's event from August 5-13, 2017 at Aviva Centre in Toronto and its 137th anniversary of the men's event from August 4-13, 2017 at Uniprix Stadium in Montreal. As a WTA Premier 5 tournament, ATP World Tour Masters 1000 tournament, and US Open Series event, Rogers Cup presented by National Bank will showcase the world's best players. Rogers Cup presented by National Bank is the third-oldest title in tennis, behind only Wimbledon and the US Open. For more information and tickets visit <a href="https://www.rogerscup.com">Rogers Cup</a>.</p>
            <h2>About Tennis Canada</h2>
            <p>Founded in 1890, Tennis Canada is a non-profit, national sport association with a mission to lead the growth of tennis in Canada and a vision to become a world-leading tennis nation. We value teamwork, passion, integrity, innovation and excellence. Tennis Canada owns and operates the premier Rogers Cup presented by National Bank WTA and ATP World Tour events, 10 professional ITF-sanctioned events and financially supports 12 other professional tournaments in Canada. Tennis Canada operates national junior training centres/programs in Toronto, Montreal, Vancouver, and Calgary. Tennis Canada is a proud member of the International Tennis Federation, the Canadian Olympic Committee, the Canadian Paralympic Committee and the International Wheelchair Tennis Association, and serves to administer, sponsor and select the teams for Davis Cup, Fed Cup, the Olympic and Paralympic Games and all wheelchair, junior and senior national teams. Tennis Canada invests its surplus into tennis development. For more information on Tennis Canada, please visit our website at <a href="http://www.tenniscanada.com">Tennis Canada</a> and follow us on <a href="http://www.facebook.com/SmileStylistTO/">Facebook</a> and <a href="http://www.twitter.com/smilestylistTO">Twitter</a>.</p>

            <h2>About Dr. Hisham Kaloti</h2>
            <p>A Realself.com 2016 Top Doc and cosmetic dentist of the stars, Dr. Kalolti is quite the familiar face in Hollywood (or should we say, familiar smile). Over the course of 18 years, Dr. Kaloti earned his name as the “celebrity Smile Stylist”, having worked on the smiles of celebrities such as Gretchen Rossi of the Real Housewives of Orange County, Peta Murgatroyd of Dancing with the Stars, and Kat Callaghan of Z103.5.</p>
            <p>“It’s not every day that an opportunity like this arises. Why not support Tennis Canada and watch the world’s best players in action this August?!” Dr. Kaloti remarks.  “The event never disappoints. For tennis fans in North America, the Rogers Cup is really something to smile about.”</p>
            <img alt=" Partners With Tennis Canada and Rogers Cup" src={ require("../../images/desktop/_pressrelease/pics/kaloti3.png" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.6s" /><br />
            <p>Toronto’s Smile Stylist and Tennis Canada will be offering 20% off Platinum Plus - Gold tickets and 15% off Silver - Bronze tickets on matches running from August 7th to the 12th.</p>
            <p>Offer terminated</p>
            <br />
            <p>To learn more about Tennis Canada and Rogers Cup, please visit:</p>
            <p><a href="https://www.rogerscup.com">Rogers Cup</a></p>
            <p>To learn more about the Smile Stylist TO’s cosmetic dentistry services, please contact:</p>
            <ul className="list-none">
              <li>The Smile Stylist</li>
              <li>123 Trafalgar Rd, Oakville, ON L6J 3G4, Canada</li>
              <li>info@thesmilestylist.ca</li>
              <li>(905) 849-0558</li>
            </ul>

          </div>
          <div className="col-sm-4 blog-right">
            <img alt="Partners With Tennis Canada and Rogers Cup" src={ require("../../images/desktop/_pressrelease/pics/kaloti1.png" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.6s" /><br />
            <img alt="Tennis Canada Rogers Club" src={ require("../../images/desktop/_pressrelease/pics/kaloti4.png" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.6s" />


          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage